import dayjs from 'dayjs'
import 'dayjs/locale/es'
import duration from 'dayjs/plugin/duration'

dayjs.locale('es')
dayjs.extend(duration)

export class DateGenerator {
  static capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  static formatDayJsDate (date) {
    const formatted = dayjs(date).format('DD/MMM/YYYY')
    const parts = formatted.split('/')
    parts[1] = this.capitalizeFirstLetter(parts[1].toLowerCase())
    return parts.join('/')
  }

  static generateDates (year, includeAugust = true) {
    if (!year) return null

    const dates = []

    for (let i = year - 10; i <= year + 1; i++) {
      if (i === year) {
        dates.push({
          internal: this.formatDayJsDate(`${year}-03-31`),
          display: `Mar/${year}`,
        })
        if (includeAugust) {
          dates.push({
            internal: this.formatDayJsDate(`${year}-08-31`),
            display: `Ago/${year}`,
          })
        }
        continue
      }

      dates.push({
        internal: this.formatDayJsDate(`${i}-03-31`),
        display: `Mar/${i}`,
      })

      if (includeAugust && i !== year + 1) {
        dates.push({
          internal: this.formatDayJsDate(`${i}-08-31`),
          display: `Ago/${i}`,
        })
      }
    }

    return dates.sort((a, b) => {
      const [dayA, monthA, yearA] = a.internal.split('/')
      const [dayB, monthB, yearB] = b.internal.split('/')
      if (yearA !== yearB) {
        return yearB.localeCompare(yearA)
      }
      return monthA === 'Ago' ? -1 : 1
    })
  }

  static calculateMonthsDifference (selectedDate, inputDate) {
    if (!selectedDate || !inputDate) return 0

    const [day, month, year] = selectedDate.split('/')
    const monthMap = { Ago: 8, Mar: 3 }
    const isoDate = `${year}-${monthMap[month]}-${day}`
    const date1 = dayjs(isoDate)

    if (!date1.isValid()) {
      return 0
    }

    const diff = Math.round(dayjs.duration(inputDate.diff(date1)).asMonths())

    if (diff >= 36) return 3.5
    if (diff >= 30) return 3
    if (diff >= 24) return 2.5
    if (diff >= 18) return 2
    if (diff >= 12) return 1.5
    if (diff >= 6) return 1
    if (diff >= 1) return 0.5
    return 0
  }

  static toValidDBDate (selectedDate) {
    const [day, month, year] = selectedDate.split('/')
    const monthMap = { Ago: 8, Mar: 3 }
    const isoDate = `${year}-${monthMap[month]}-${day}`
    const date = dayjs(isoDate)

    if (!date.isValid()) {
      return null
    }

    return date.format('DD/MM/YYYY')
  }

  static calculateMonthsDifferenceWithCurrentDate (selectedDate) {
    if (!selectedDate) return null

    const [day, month, year] = selectedDate.split('/')
    const monthMap = {
      Mar: 3,
      Ago: 8,
      Ene: 1,
      Feb: 2,
      Abr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dic: 12,
    }

    const isoMonth = monthMap[month]
    if (!isoMonth) return null

    const isoDate = `${year}-${isoMonth}-${day}`
    const date1 = dayjs(isoDate)

    if (!date1.isValid()) {
      return null
    }

    const currentDate = dayjs()
    return dayjs.duration(date1.diff(currentDate)).asMonths()
  }

  static findGeneratedDate (givenDate) {
    if (!givenDate) return null

    const [day, month, year] = givenDate.split('/')

    const generatedDates = this.generateDates(parseInt(year))
    const isoDate = `${year}-${month}-${day}`
    const formattedDate = this.formatDayJsDate(isoDate)

    const foundDate = generatedDates.find(date => date.internal === formattedDate)

    if (foundDate) {
      return foundDate
    } else if (month !== 'Mar' && month !== 'Ago') {
      const splitFormattedDate = formattedDate.split('/')
      const display = `${splitFormattedDate[1]}/${year}`
      return { internal: formattedDate, display }
    }

    return null
  }

  static calculateMonthByDigit (digit) {
    if (digit === null || digit === undefined) return null

    const monthMap = {
      Ene: 1,
      Feb: 2,
      Abr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Ago: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
    }

    const months = [
      'Feb', // 0
      'Abr', // 1
      'May', // 2
      'Jun', // 3
      'Jul', // 4
      'Ago', // 5
      'Sep', // 6
      'Oct', // 7
      'Nov', // 8
      'Ene', // 9
    ]

    const month = months[digit]
    if (!month) return null

    const dates = []

    const currentYear = new Date().getFullYear()
    const futureYear = currentYear + 1
    const pastYear = currentYear - 10

    for (let year = pastYear; year <= futureYear; year++) {
      const endOfMonth = dayjs(`${year}-${monthMap[month]}-01`).endOf('month')
      const isoDate = endOfMonth.format('YYYY-MM-DD')
      const internal = this.formatDayJsDate(isoDate)
      const display = internal.split('/')[1] + '/' + year
      dates.push({
        internal,
        display,
      })
    }

    return dates.reverse()
  }
}
